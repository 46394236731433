import React from "react";

const styles = require("./CallOuts.module.scss");

interface Props {}

const SupportCallOut: React.FunctionComponent<Props> = () => {
	return (
		<div className={styles.SupportCallOut}>
			<div className={styles.Content}>
				<img src="https://cdn.prestavi.com/website/images/triangle-shape.svg" alt=""/>

				<div>
					<h2>Want to speak with a Prestavi expert?</h2>
					<p>We can help you determine if Prestavi is the best solution for your organization.</p>

					<div className={styles.Actions}>
						<a href="/demo">
							Schedule a demo
						</a>
						<span>or</span>
						<a href="/schedule-a-call">
							Schedule a call
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SupportCallOut;
