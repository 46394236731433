// extracted by mini-css-extract-plugin
export var Blob = "Jobs-module--Blob--ZkqZt";
export var Button = "Jobs-module--Button---+Hm4";
export var Circle = "Jobs-module--Circle--S-KOP";
export var Container = "Jobs-module--Container--QkG6M";
export var Content = "Jobs-module--Content--W2W1g";
export var CurvyLine = "Jobs-module--CurvyLine--HIdLt";
export var ExampleJobSteps = "Jobs-module--ExampleJobSteps--eSjSv";
export var Headline = "Jobs-module--Headline--oznbu";
export var Hero = "Jobs-module--Hero--pAPfF";
export var JobOverview = "Jobs-module--JobOverview--pJcyn";
export var JobsList = "Jobs-module--JobsList--Hkv5g";
export var Logs = "Jobs-module--Logs--Jg4nY";
export var Media = "Jobs-module--Media--2KsC-";
export var Overview = "Jobs-module--Overview--ReYUn";
export var ShortLine = "Jobs-module--ShortLine--8nKkA";
export var Timeline = "Jobs-module--Timeline--0TJ7R";
export var Triangle = "Jobs-module--Triangle--scWrm";