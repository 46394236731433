import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import Button from "../../../Button/Button";
import Hero from "./Hero";
import SupportCallOut from "../../Callouts/SupportCallOut";
import Footer from "../../../Footer/Footer";

const styles = require("./Jobs.module.scss");

interface Props {}

const Automations: React.FunctionComponent<Props> = () => {

	return (
		<div className={styles.Automations}>
			<Hero />

			<div className={styles.JobOverview}>
				<div className={styles.Container}>
					<div className={styles.Headline}>
						<h1>Full visibility into what's happened</h1>
						<h2>From a quick overview to all of the nitty gritty details.</h2>
						<p>Prestavi makes it easy to always know which work is waiting on you or someone else. Each job has a record with everything that's happened, including detailed logs.</p>
					</div>
				</div>

				<div className={styles.Media}>
					<img className={styles.Timeline} src="https://cdn.prestavi.com/website/images/job-timeline-preview.png" alt=""/>
					<img className={styles.Logs} src="https://cdn.prestavi.com/website/images/job-logs-example.png" alt=""/>

					<svg className={styles.Blob} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 221.635 221.635">
						<path d="M197.759,36.608c-3.488-28.562-45.083-39.1-83.941-36.12C60.6,4.57-54.282.147,29.877,84.138c80.559,80.4,171.847-15.067,167.882-47.53" transform="translate(139.925 221.635) rotate(-135)" fill="#5583ed"/>
					</svg>

					<svg className={styles.Triangle} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 145.051 145.05">
						<g transform="translate(392.974 3095.025) rotate(180)">
							<path d="M132.673,0V132.674h-32.03V32.03H0V0Z" transform="translate(380.597 3095.025) rotate(180)" fill="#54b997"/>
							<path d="M161.27,31.347H31.347v29.28H131.991V161.27h29.28Zm2.751-2.751V164.02h-34.78V63.376H28.6V28.6Z" transform="translate(421.571 3113.995) rotate(180)" fill="#b9e5d6"/>
						</g>
					</svg>
				</div>
			</div>

			<div className={styles.JobsList}>
				<div className={styles.Container}>
					<div className={styles.Headline}>
						<h1>Know where everything is at.</h1>
						<h2>Columns, filters and fine-grained access control.</h2>
						<p>Get a bird's eye view of all of your jobs and create custom views to filter and see exactly what you need to. </p>
					</div>
				</div>

				<div>
					<svg className={styles.Circle} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 280.907 262.115">
						<g>
							<path d="M258.157,129.079A129.079,129.079,0,1,1,129.079,0,129.079,129.079,0,0,1,258.157,129.079" fill="#92d6bf"/>
							<path d="M149.336,262.115a131.28,131.28,0,0,1-21.386-3.53l1.271-5.047a126.082,126.082,0,0,0,20.536,3.389ZM171,262.1l-.427-5.187a126.12,126.12,0,0,0,20.531-3.414l1.277,5.046A131.33,131.33,0,0,1,171,262.1M107.43,251.559A131.222,131.222,0,0,1,88.36,241.236l2.848-4.357a126.043,126.043,0,0,0,18.311,9.913Zm105.458-.059-2.1-4.765a126.088,126.088,0,0,0,18.289-9.924l2.851,4.354A131.372,131.372,0,0,1,212.888,251.5M71.251,227.922a132.366,132.366,0,0,1-14.7-15.932l4.1-3.2a127.078,127.078,0,0,0,14.117,15.3Zm177.773-.084-3.528-3.827a127.2,127.2,0,0,0,14.1-15.319l4.106,3.2a132.386,132.386,0,0,1-14.681,15.947M44.673,193.869a130.9,130.9,0,0,1-8.731-19.828l4.921-1.7a125.588,125.588,0,0,0,8.384,19.037Zm230.9-.12L271,191.268a125.776,125.776,0,0,0,8.37-19.066l4.923,1.688a130.9,130.9,0,0,1-8.718,19.858M30.61,153.028a132.575,132.575,0,0,1-1.794-21.6l5.2-.006a127.456,127.456,0,0,0,1.724,20.748Zm258.988-.156-5.134-.85a127.424,127.424,0,0,0,1.7-20.748v-.306h5.205v.293a132.661,132.661,0,0,1-1.769,21.611m-253.9-42.2-5.136-.843a130.794,130.794,0,0,1,5.279-21.025l4.925,1.683A125.606,125.606,0,0,0,35.7,110.674m248.718-.446a125.415,125.415,0,0,0-5.141-20.167l4.92-1.7a130.77,130.77,0,0,1,5.354,21.006ZM49.112,71.412l-4.578-2.475A131.951,131.951,0,0,1,56.383,50.78l4.109,3.194a126.624,126.624,0,0,0-11.38,17.438m221.755-.379a126.717,126.717,0,0,0-11.434-17.391l4.1-3.207a131.808,131.808,0,0,1,11.905,18.108ZM74.577,38.639l-3.532-3.823A132.229,132.229,0,0,1,88.12,21.469l2.858,4.351a126.911,126.911,0,0,0-16.4,12.82M245.3,38.355a126.955,126.955,0,0,0-16.448-12.769l2.842-4.36a132.249,132.249,0,0,1,17.124,13.293ZM109.254,15.872l-2.1-4.762a130.579,130.579,0,0,1,20.493-7.07l1.282,5.044a125.46,125.46,0,0,0-19.674,6.788M210.527,15.7a125.436,125.436,0,0,0-19.709-6.722l1.264-5.05a130.7,130.7,0,0,1,20.528,7Zm-61.07-10.05L149.023.459A133.829,133.829,0,0,1,170.693.42l-.415,5.188a128.733,128.733,0,0,0-20.821.036" transform="translate(-10.46 0)" fill="#1f49ab"/>
						</g>
					</svg>

					<img className={styles.Overview} src="https://cdn.prestavi.com/website/images/list-of-jobs.png" alt=""/>

				</div>
			</div>

			<div className={styles.CurvyLine}>
				<svg xmlns="http://www.w3.org/2000/svg" width="196.48" height="18.694" viewBox="0 0 196.48 18.694">
					<g transform="translate(-564.13 -2869.371)">
						<g transform="translate(564.13 2869.371)">
							<g transform="translate(0 0)">
								<path d="M3.116,18.694c6.274,0,9.412-4.139,11.934-7.464,2.422-3.192,3.939-5,6.969-5s4.548,1.806,6.969,5c2.522,3.326,5.661,7.464,11.934,7.464s9.411-4.139,11.933-7.464c2.421-3.192,3.938-5,6.968-5s4.547,1.806,6.968,5c2.522,3.326,5.66,7.464,11.933,7.464s9.411-4.139,11.933-7.464c2.421-3.192,3.938-5,6.968-5a3.116,3.116,0,0,0,0-6.231c-6.273,0-9.412,4.139-11.934,7.464-2.42,3.192-3.938,5-6.967,5s-4.547-1.806-6.967-5C69.235,4.139,66.1,0,59.824,0S50.413,4.139,47.891,7.464c-2.421,3.192-3.938,5-6.968,5s-4.548-1.806-6.969-5C31.432,4.139,28.293,0,22.019,0S12.608,4.139,10.085,7.464c-2.421,3.192-3.939,5-6.969,5a3.116,3.116,0,1,0,0,6.231" transform="translate(0 0)" fill="#f8dea1"/>
							</g>
						</g>
						<g transform="translate(659.87 2869.371)">
							<g transform="translate(0 0)">
								<path d="M97.625,18.694c-6.274,0-9.412-4.139-11.934-7.464-2.422-3.192-3.939-5-6.969-5s-4.548,1.806-6.969,5c-2.522,3.326-5.661,7.464-11.934,7.464s-9.411-4.139-11.933-7.464c-2.421-3.192-3.938-5-6.968-5s-4.547,1.806-6.968,5c-2.522,3.326-5.66,7.464-11.933,7.464s-9.411-4.139-11.933-7.464c-2.421-3.192-3.938-5-6.968-5A3.116,3.116,0,0,1,3.116,0c6.273,0,9.412,4.139,11.934,7.464,2.42,3.192,3.938,5,6.967,5s4.547-1.806,6.967-5C31.506,4.139,34.644,0,40.917,0S50.328,4.139,52.85,7.464c2.421,3.192,3.938,5,6.968,5s4.548-1.806,6.969-5C69.309,4.139,72.448,0,78.721,0s9.412,4.139,11.934,7.464c2.421,3.192,3.939,5,6.969,5a3.116,3.116,0,0,1,0,6.231" transform="translate(-0.001 0)" fill="#f8dea1"/>
							</g>
						</g>
					</g>
				</svg>
			</div>

			<SupportCallOut />

			<Footer />
		</div>
	);
};

export default Automations;
