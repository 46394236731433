import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import Button from "../../../Button/Button";

const styles = require("./Jobs.module.scss");

interface Props {}

const Hero: React.FunctionComponent<Props> = () => {
	// const stepRequestImgRef: MutableRefObject<HTMLImageElement|null> = useRef(null);
	// const stepReviewImgRef: MutableRefObject<HTMLImageElement|null> = useRef(null);
	// const workflowImgRef: MutableRefObject<HTMLImageElement|null> = useRef(null);
	// const [lineCoords, setLineCoords] = useState([
	// 	{
	// 		x1: 0,
	// 		y1: 0,
	// 		x2: 0,
	// 		y2: 0
	// 	},
	// 	{
	// 		x1: 0,
	// 		y1: 0,
	// 		x2: 0,
	// 		y2: 0
	// 	},{
	// 		x1: 0,
	// 		y1: 0,
	// 		x2: 0,
	// 		y2: 0
	// 	}
	// ]);


	// function calculateCoords() {
	// 	const submitRequestImage = stepRequestImgRef.current?.getBoundingClientRect();
	// 	const reviewRequestImage = stepReviewImgRef.current?.getBoundingClientRect();
	// 	const workflowEditorImage = workflowImgRef.current?.getBoundingClientRect();
	//
	// 	if (!submitRequestImage || !reviewRequestImage || !workflowEditorImage) {
	// 		return;
	// 	}
	//
	// 	// first line
	// 	let nextLineCoords = [...lineCoords];
	// 	nextLineCoords[0] = {
	// 		x1: submitRequestImage.width / 2,
	// 		y1: submitRequestImage.height,
	// 		x2: workflowEditorImage.x + 90,
	// 		y2: workflowEditorImage.y + 110
	// 	}
	//
	// 	// second line
	// 	nextLineCoords[1] = {
	// 		x1: reviewRequestImage.width / 2 + 50,
	// 		y1: submitRequestImage.height + reviewRequestImage.height + 26,
	// 		x2: workflowEditorImage.x + 300,
	// 		y2: workflowEditorImage.y + 110
	// 	}
	//
	// 	// third line
	// 	nextLineCoords[2] = {
	// 		x1: reviewRequestImage.width / 2,
	// 		y1: submitRequestImage.height + reviewRequestImage.height,
	// 		x2: workflowEditorImage.x + 230,
	// 		y2: workflowEditorImage.y + 208
	// 	}
	//
	// 	setLineCoords(nextLineCoords);
	// }

	// useEffect(() => {
	// 	calculateCoords();
	//
	// 	window.addEventListener('resize', calculateCoords);
	// }, [])

	return (
		<div className={styles.Hero}>
			<div className={styles.Content}>
				<h3>Jobs</h3>

				<div>
					<h1>Bring your workflows to life with jobs.</h1>

					<div className={styles.ShortLine}></div>
					<p>Make it easy for your people to always know how to complete their part. Great for tasks that happen several times a day or obscure work that only happens every few months.</p>
					<p>Prestavi gives you the confidence to know that your people will never forget how to complete their part of a process and that it will be done right every time. </p>

					<Button className={styles.Button} onClick={() => window.location.href = "https://app.prestavi.com/signup"} theme="solid" tone="dark" color="primary" size="standard">
						Start your free trial
						<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11.028 18.393">
							<g transform="translate(0 0)">
								<path d="M1.27,18.393a1.27,1.27,0,0,1-.891-2.174L7.9,8.811.43,2.222A1.27,1.27,0,1,1,2.11.318L10.6,7.808a1.269,1.269,0,0,1,.051,1.856L2.161,18.028a1.267,1.267,0,0,1-.891.365" fill="#265dde"/>
							</g>
						</svg>

					</Button>


				</div>
			</div>

			<div className={styles.ExampleJobSteps}>
				{/*<div className={styles.Lines}>*/}
				{/*	<svg>*/}
				{/*		<line strokeWidth="2px" stroke="#92D6BF" {...lineCoords[0]} />*/}
				{/*		<g transform={`translate(${lineCoords[0].x2 - 10}, ${lineCoords[0].y2 - 10})`}>*/}
				{/*			<circle cx="10" cy="10" r="10" fill="#92D6BF"/>*/}
				{/*		</g>*/}
				{/*	</svg>*/}
				{/*	<svg>*/}
				{/*		<line strokeWidth="2px" stroke="#1F49AB" {...lineCoords[1]} />*/}
				{/*		<g transform={`translate(${lineCoords[1].x2 - 10}, ${lineCoords[1].y2 - 10})`}>*/}
				{/*			<circle cx="10" cy="10" r="10" fill="#1F49AB"/>*/}
				{/*		</g>*/}
				{/*	</svg>*/}
				{/*	<svg>*/}
				{/*		<line strokeWidth="2px" stroke="#F8DEA1" {...lineCoords[2]} />*/}
				{/*		<g transform={`translate(${lineCoords[2].x2 - 10}, ${lineCoords[2].y2 - 10})`}>*/}
				{/*			<circle cx="10" cy="10" r="10" fill="#F8DEA1"/>*/}
				{/*		</g>*/}
				{/*	</svg>*/}
				{/*</div>*/}
				<img src="https://cdn.prestavi.com/website/images/submit-open-job-step.png" alt="Job step user interface submitting a request"/>
				<img src="https://cdn.prestavi.com/website/images/review-open-job-step.png" alt="Job step user interface reviewing a request"/>
			</div>
		</div>
	);
};

export default Hero;
